// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "BlogPostNewForm__buttonCreateContainer__b96gU";
export var column = "BlogPostNewForm__column__KeKyt";
export var documentRow = "BlogPostNewForm__documentRow__Y_9CH";
export var editor = "BlogPostNewForm__editor__N8FlB";
export var fileNameText = "BlogPostNewForm__fileNameText__XDHhw";
export var fileSizeText = "BlogPostNewForm__fileSizeText__qvi9P";
export var flexContainer = "BlogPostNewForm__flexContainer__nj8Jv";
export var grid = "BlogPostNewForm__grid__EYiXZ";
export var iconDelete = "BlogPostNewForm__iconDelete__AHPOd";
export var iconView = "BlogPostNewForm__iconView__IIKXT";
export var labelContainer = "BlogPostNewForm__labelContainer__fC_gk";
export var publishedAtPicker = "BlogPostNewForm__publishedAtPicker__IuwIO";
export var row = "BlogPostNewForm__row__I9bD8";
export var uploadDocumentButton = "BlogPostNewForm__uploadDocumentButton__YEzoW";